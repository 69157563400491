import { Pipe, PipeTransform } from '@angular/core';
import { STATUS } from 'src/app/common/app.constants';
@Pipe({
  name: 'driverstatus'
})
export class DriverstatusPipe implements PipeTransform {
  stausArr = [...STATUS];
  transform(value: any, ...args: any[]): any {
    const data = this.stausArr.filter(obj => {
      return obj.KEY === value;
    })
    return data[0];
  }

}
