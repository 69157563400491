// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://192.168.2.56:3000', //Local
  // baseUrl: 'http://192.168.2.138:5000', //Local
  // baseUrl: 'http://146.148.97.23:4100'
  baseUrl: 'https://api.mevron.com'
};