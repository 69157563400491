import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { USER, NAVIGATION_ROUTES } from 'src/app/common/app.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {
  constructor(private router: Router) { }

  isAuthenticated(): boolean {
    if (localStorage.getItem(USER.REMEMBERME)) {
      return !!localStorage.getItem(USER.ACCESS_TOKEN);
    }
    return !!sessionStorage.getItem(USER.ACCESS_TOKEN);
  }
  isRider(): boolean {
    if (this.getUserData(USER.TYPE) === '1') {
      return true;
    }
    return false;
  }
  rememberMe(response) {
    localStorage.setItem(USER.REMEMBERME, USER.REMEMBERME);
    localStorage.setItem(USER.ACCESS_TOKEN, response.token);
    localStorage.setItem(USER.ID, response.data._id);
  }
  sessionStart(response) {
    sessionStorage.setItem(USER.ACCESS_TOKEN, response.token);
    sessionStorage.setItem(USER.ID, response.data._id);
  }

  saveToken(token) {
    sessionStorage.setItem(USER.ACCESS_TOKEN, token);
  }
  saveUserData(key, val) {
    if (key == USER.REMEMBERME) {
    }
    sessionStorage.setItem(key, val);
  }

  getUserData(key) {
    if (localStorage.getItem(USER.REMEMBERME)) {
      return localStorage.getItem(key);
    }
    return sessionStorage.getItem(key);
  }

  logout() {
    if (localStorage.getItem(USER.REMEMBERME)) {
      localStorage.clear();
    } else {
      sessionStorage.clear();
    }
    this.router.navigate([NAVIGATION_ROUTES.LOGIN]);
  }

  removeUserData(key) {
    sessionStorage.removeItem(key);
  }
}
