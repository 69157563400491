export const ERRORS = {
    VALIDATIONS: {
        REQUIRED_FIELD: 'This field is required.',
        INVALID_FIELD: 'Invalid field.',
        INVALID_EMAIL: 'Please enter valid email id.',
        INVALID_CONTACT_NO: 'Please enter valid contact.',
        PROMO_EXPIRY_DATE: 'Date can not be less then current date.'
    }

}
export const USER = {
    TYPE: 'usertype',
    ACCESS_TOKEN: 'authorization',
    ID: 'uid',
    REMEMBERME: 'rememberMe',
}
export const NAVIGATION_ROUTES = {
    LOGIN: '/auth/login',
    DASHBOARD: '/dashboard',
    PROMOCODE: '/dashboard/promo-code',
    ADD_PROMOCODE: '/dashboard/promo-code/add-promo',
    EDIT_PROMOCODE: '/dashboard/promo-code/edit-promo',
    SURGE_PRICING: '/dashboard/surge-pricing#mannual',
    ADD_SURGE_PRICING: '/dashboard/surge-pricing/add-surge',
    ADD_AUTO_SURGE: '/dashboard/surge-pricing/add-auto-surge',
    EDIT_SURGE_PRICING: '/dashboard/surge-pricing/edit-surge',
}
export const PROMO_CODE_FORM = {
    PROMO_CODE: 'Promo Code',
    DISCOUNT_TYPE: 'Discount Type',
    MAXIMUM_DISCOUNT: 'Maximum Discount',
    PERCENTAGE: 'Percentage',
    AMOUNT: 'Amount',
    START_DATE: 'Start Date',
    EXPIRY_DATE: 'Expiry Date',
    MIN_FARE_DISCOUNT: 'Min Fare For Discount'
}
export const SERGE_PRICE_CONFIG_FORM = {
    SURGE_TYPE: 'Surge Type',
    TITLE: 'Title',
    FLAT: 'Flat',
    STATUS: 'Status',
    NOTIFICATION_RADIUS: 'Notification Radius',
    PERCENTAGE: 'Surge Percentage',
    DATE: 'Date',
    START_DATE: 'Start Time',
    END_DATE: 'End Time',
    RECURRING: 'Recurring'
}
export const PAGINATION = {
    ITEM_PER_PAGE: 5,
    INITIAL_PAGE: 1
}
export const MESSAGE = {
    PROMOCODE_ADDED: 'Promo code added successfully',
    PROMOCODE_UPDATED: 'Promo code updated successfully',
    FILE_NAME: 'Enter file name',
    CONFIRM: 'Are you sure?',
    DELETE_WARNING: 'This record may never be recovered.',
    DEACTIVATE_STATUS: 'badge badge-danger'
}
export const COMPONENT_ATR_CONST = {
    DRIVER_SEARCH_PLACEHOLDER: 'Search By Driver Name, Id, Mobile number',
    PROMO_SEARCH_PLACEHOLDER: 'Search By Promo Code',
    DRIVER_SEARCH_LABEL: 'Search Driver',
}

export const GEOFENCING = {
    CIRCEL_RADIUS: 20,
    POLYGON_TYPE: 1,
    CIRCLE_TYPE: 2,
}
export const TABLE_HEADERS = {
    DRIVERS: [
        { NAME: 'DRIVER ID', KEY: 'uId', },
        { NAME: 'NAME', KEY: 'name', CLICKABLE: true, CLICKABLE_LINK: '/dashboard/driver-status/driver-profile' },
        { NAME: 'MOBILE', KEY: 'phoneNumber' },
        { NAME: 'VEHICLE TYPE', KEY: 'vehicleType' },
        { NAME: 'TRIPS', KEY: 'numberOfRides' },
        { NAME: 'CREATED AT', KEY: 'createdAt', DATE: true },
        { NAME: 'STATUS', KEY: 'status' },
    ],
    SURGE_PRICING: [
        { NAME: 'TITLE', KEY: 'title' },
        { NAME: 'PERCENT', KEY: 'amount' },
        { NAME: 'DATE', KEY: 'date', DATE: true },
        { NAME: 'START DATE', KEY: 'startDate', DATE: true },
        { NAME: 'END DATE', KEY: 'endDate', DATE: true },
        { NAME: 'START TIME', KEY: 'startTime', TIME: true },
        { NAME: 'END TIME', KEY: 'endTime', TIME: true },
        { NAME: 'RECURRING', KEY: 'isRecurring' },
        { NAME: 'STATUS', KEY: 'isEnable', TYPE2: true },
        { NAME: 'ACTION', KEY: 'action', EDIT_LINK: '/dashboard/surge-pricing/edit-surge', DELETE_URL: "/v1/admin/deleteManualSurge" },
    ],
    AUTO_SURGE: [
        { NAME: 'COUNTRY', KEY: 'country' },
        { NAME: 'STATE', KEY: 'state' },
        { NAME: 'CITY', KEY: 'city' },
        { NAME: 'NOTIFICATION RADIUS', KEY: 'notificationRadius' },
        { NAME: 'SURGE RADIUS', KEY: 'surgeRadius' },
        { NAME: 'MIN FULFILLMENT RATIO', KEY: 'minFulFillment' },
        { NAME: 'ACTION', KEY: 'action', EDIT_LINK: '/dashboard/surge-pricing/edit-auto-surge', DELETE_URL: "/v1/admin/automaticSurge" },
    ]
}

export const STATUS = [
    { NAME: 'PENDING', KEY: 1, BADGE: 'badge badge-warning' },
    { NAME: 'APPROVED', KEY: 2, BADGE: 'badge badge-success' },
    { NAME: 'REJECTED', KEY: 3, BADGE: 'badge badge-danger' },
]
export const DRICER_FETCH_TYPE = {
    ACTIVE_DRIVER: 1,
    DEACTIVATE_DRIVER: 2,
    ALL_DRIVER: 3
}
export const DRIVER_STATUS = {
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3
}
export const ALERT_SERVICE = {
    INPUT_TYPE: {
        TEXTAREA: 'textarea',
        TEXT: 'text'
    }
}
export const VEHICLE_TYPE = [
    { TYPE: 1, NAME: 'Mevron Bike', URL: 'assets/img/front/bike_icon.svg' },
    { TYPE: 2, NAME: 'Mevron Trike', URL: 'assets/img/front/wheeler_icon.svg' },
    { TYPE: 3, NAME: 'Mevron Economy', URL: 'assets/img/front/ride_icon.svg' },
    { TYPE: 4, NAME: 'Mevron Standard', URL: 'assets/img/front/ride_icon.svg' },
    { TYPE: 5, NAME: 'Mevron Luxury', URL: 'assets/img/front/ride_icon.svg' },
]
export const FRAGMENTS = {
    ACTIVE_DRIVERS: 'active',
    DEACTIVATE_DRIVER: 'deactivated',
    ALL_DRIVER: 'all'
}