import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { PromoPipe } from '../core/pipes/promo.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TableComponent } from './table/table.component';
import { SearchComponent } from './search/search.component';
import { DriverstatusPipe } from '../core/pipes/driverstatus.pipe';
import { LoaderComponent } from './loader/loader.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterComponent } from './filter/filter.component';
import { PaginationComponent } from './pagination/pagination.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { VehiclePipe } from '../core/pipes/vehicle.pipe';


@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    PromoPipe,
    SearchComponent,
    DriverstatusPipe,
    LoaderComponent,
    PaginationComponent,
    FilterComponent,
    TableComponent,
    VehiclePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    RouterModule,
    PromoPipe,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    SearchComponent,
    DriverstatusPipe,
    LoaderComponent,
    FilterComponent,
    PaginationComponent,
    TableComponent,
    NgMultiSelectDropDownModule,
    VehiclePipe
  ],
  providers: [DriverstatusPipe, VehiclePipe]
})
export class SharedModule { }
