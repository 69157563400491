import { Pipe, PipeTransform } from '@angular/core';
import { VEHICLE_TYPE } from 'src/app/common/app.constants';

@Pipe({
  name: 'vehicle'
})
export class VehiclePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value) {
      return VEHICLE_TYPE.find(obj => {

        return obj.TYPE === value;
      })
    }
    return null;
  }

}
