import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthUserService } from '../services/auth-user.service';
import { tap } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { AlertService } from '../services/alert.service';
import { USER } from 'src/app/common/app.constants';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private authUserService: AuthUserService,
    private loaderService: LoaderService,
    private alertService: AlertService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.show();
    if (this.authUserService.isAuthenticated()) {
      const token = this.authUserService.getUserData(USER.ACCESS_TOKEN);
      req = req.clone({ headers: req.headers.set(USER.ACCESS_TOKEN, token) });
    }
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loaderService.hide();
        }
      }, (err) => {
        this.loaderService.hide();
        if (err instanceof HttpErrorResponse) {
          if (err.error.statusCode === 401) {
            this.alertService.errorToast('Unauthorized user')
            this.authUserService.logout();
          }
        }
      })
    );
  }

}
