import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { error } from '@angular/compiler/src/util';
// import { ToastObj as this.toastObj } from 'src/app/common/constant';

@Injectable(
    {
        providedIn: 'root'
    }
)
export class AlertService {

    obj;
    confirmAlert;
    toastObj;
    constructor() {
        // Toast object init
        this.obj = {
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
        };

        // confirm alert init
        this.confirmAlert = {
            type: '',
            title: '',
            text: '',
            showCancelButton: true,
            confirmButtonColor: '#e99c34',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            cancelButtonColor: '#787878',
            cancelButtonClass: '',
            buttonsStyling: true
        };
        this.toastObj = { type: '', title: '' };
    }

    toastShow(obj) {
        const Toast = Swal.mixin(this.obj);
        return Toast.fire(obj);
    }

    successToast(message) {
        this.toastObj.type = 'success';
        this.toastObj.title = message;
        this.toastShow(this.toastObj).then(obj => { }).catch(err => { });
    }
    errorToast(message) {
        this.toastObj.type = 'error';
        this.toastObj.title = message;
        this.toastShow(this.toastObj).then(obj => { }).catch(err => { });
    }

    simplePopUp(obj) {
        Swal.fire({
            title: obj.title,
            text: obj.msg,
            type: 'warning'
        });
    }

    confirmPopUp(obj) {
        this.confirmAlert.title = obj.title;
        this.confirmAlert.type = obj.type;
        this.confirmAlert.text = obj.text;
        return Swal.fire(this.confirmAlert);
    }

    input(title, inputType) {
        return Swal.fire({
            title: title,
            input: inputType,
            showCancelButton: true,
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    if (value) {
                        resolve();
                    } else {
                        resolve('Please enter file name');
                    }
                });
            },
            customClass: 'required'
        });
    }
}
